import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import FilterMultiSelect from "./FilterMultiSelect"



interface FilterSelect {
    title:string,
    list: any[],
    idKey: string,
    getName: (valor:any) => string,
    setValue: (value: string) => void,
    value: string,
    width?: string | number,
    disableAll?: boolean,
    forceTextValue?: string,
    buttons?: {title: string, active: boolean, action: () => void, icon?: JSX.Element}[],
}


const FilterSelect = ({ title, buttons, list, idKey, setValue, getName, value, width, disableAll, forceTextValue}:FilterSelect) => {


    return (
        <FormControl sx={{width: width ?? "200px", mr:2, my: 1,}} size="small">
        <InputLabel id="simple-select">{title}</InputLabel>
        <Select
          labelId="simple-select"
          id="form-simple-select"
          value={value}
          label={title}
          size="small"
          color="primary"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          renderValue={(selected) => forceTextValue ? forceTextValue : selected == 'all' ? 'Todos' : getName(list.filter((v) => v[idKey] == selected)[0])}
          onChange={(e) => setValue(e.target.value)}
        >
          {!disableAll && <MenuItem value={'all'}>Todos</MenuItem>}
          {list && list.map((v) => (
            <MenuItem value={v[idKey]}>{getName(v)}</MenuItem>
          ))}
          {buttons && buttons.map((valor) => (
                <MenuItem sx={{pl: 0, width: width ? width : "100%"}} onClick={valor.action} >
                    {valor.icon && <ListItemIcon sx={{mr: 0, pr: 0,  pl:0.75, minWidth: 'inherit', color: valor.active ? '#136DCD' : 'inherit'}}  >{valor.icon}</ListItemIcon>}
                    <ListItemText primary={valor.title} sx={{color: valor.active ? '#136DCD' : 'inherit'}}/>
                </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
}

export default FilterSelect
