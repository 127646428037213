import {
    CorporateFare,
    Domain,
    ExpandLess,
    ExpandMore,
    Groups,
    Input,
    Person,
    PersonSearch,
    PersonOutlined,
} from "@mui/icons-material";
import {
    Accordion,
    Badge,
    Box,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    createTheme,
    Grid,
    ThemeProvider,
    Tooltip,
    Typography,
    Chip,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setMenuOpen,
    setSelectedGroup,
    setSelectedUser,
} from "../../redux/actions/main";
import {
    FaUserTag,
    FaUserGraduate,
    FaUserAlt,
    FaUserFriends,
    FaUsers,
} from "react-icons/fa";
import _ from "lodash";

const userIcon = {
    undefined: <FaUserAlt size={18} />,
    normal: <FaUserAlt size={18} />,
    a_prueba: <FaUserGraduate size={18} />,
    independiente: <FaUserTag size={18} />,
};

const ItemUser = ({ children, user, active = false, icon, group = null }) => {
    var dispatch = useDispatch();

    return (
        <Tooltip title={children} placement="right">
            <Button
                fullWidth
                size="large"
                startIcon={icon}
                sx={{
                    fontSize: "15px",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    color:
                        user && user.color && !active
                            ? user.color
                            : active
                            ? "#0080ff"
                            : "#000",
                    borderRadius: "10px",
                    "&:hover": {
                        backgroundColor: "#ddeeff",
                        color: "#0080ff",
                    },
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }}
                onClick={() => {
                    dispatch(
                        setSelectedUser(
                            user && group ? { ...user, group } : user
                        )
                    );
                    dispatch(setSelectedGroup(group ? group : null));
                    dispatch(setMenuOpen(false));
                }}
            >
                <Typography
                    noWrap
                    width={1}
                    align="left"
                    variant="inherit"
                    sx={{ ml: 1 }}
                >
                    {children}
                </Typography>
                {user &&
                    user.userType &&
                    (user.userType == "a_prueba" ||
                        user.userType == "independiente") && (
                        <Chip
                            sx={{
                                fontSize: "10px",
                                borderRadius: "5px",
                                p: 0,
                                height: "20px",
                                "&:hover": { cursor: "pointer" },
                            }}
                            label={
                                user.userType == "a_prueba"
                                    ? "A prueba"
                                    : "Subagente"
                            }
                        />
                    )}
            </Button>
        </Tooltip>
    );
};

const theme = createTheme({
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: 0,
                    p: 0,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "&&:before": {
                        background: "#fff",
                        height: 0,
                    },
                },
            },
        },
    },
});

const generateUsersGroup = (group) => {
    if (group.group) {
        let user_group = [];
        group.group.map((grp) => {
            user_group = [...user_group, ...generateUsersGroup(grp)];
        });
        return [...user_group, ...group.users];
    }
    return group.users;
};

const generateGroup = (group, selectedUser, selectedGroup, level) => {
    let users =
        group.users &&
        group.users.filter((u) => u.active && u.roles.includes("user"));
    let extra_users = generateUsersGroup(group);

    return (
        <ItemGroup
            active={
                (selectedUser &&
                    group.users.filter((u) => selectedUser._id == u._id)
                        .length > 0) ||
                (selectedGroup && selectedGroup._id == group._id)
            }
            icon={<FaUsers size={22} />}
            defaultExpanded={level == 0}
            group={group}
        >
            <Box pl={level * 4 + "px"}>
                <ItemUser
                    active={selectedGroup && selectedGroup._id == group._id}
                    icon={<FaUserFriends size={22} />}
                    user={null}
                    group={{ ...group, users: extra_users }}
                >
                    Todo el grupo ({extra_users.filter(u => u.active && u.roles.includes('user')).length})
                </ItemUser>
                {group.group.map((grp) =>
                    generateGroup(grp, selectedUser, selectedGroup, level + 1)
                )}
                {users
                    .sort((a, b) =>
                        `${a.name} ${a.lastname}`.localeCompare(
                            `${b.name} ${b.lastname}`
                        )
                    )
                    .map((u) => (
                        <ItemUser
                            user={{ ...u, group }}
                            active={selectedUser && selectedUser._id == u._id}
                            icon={userIcon[u.userType]}
                        >
                            {u.name} {u.lastname}
                        </ItemUser>
                    ))}
                {/* {JSON.stringify(group)} */}
            </Box>
        </ItemGroup>
    );
};

const ItemGroup = ({
    children,
    group,
    active = false,
    icon,
    defaultExpanded = false,
}) => {
    let [expanded, setExpaneded] = useState(defaultExpanded);

    let handleExpand = (_, change) => {
        setExpaneded(change);
    };

    const color = useMemo(() => {
        let sum = 0, count = 0;
        let listUsers = [...group.users, ...group.group.map((g) => generateUsersGroup(g)).flat()]
        
        listUsers.map(u => {
            if(u.color == "#ED6C02"){
                sum += 1
            }else if(u.color == "#2e7d32"){
                sum += 2
            }
            if(u.color) count++
        })

        if(count * 1.5 < sum){
            return "#2e7d32"
        }else if(count * 1 < sum){
            return "#ED6C02"
        }else if(count == 0){
            return "#000"
        }else{
            return "#d32f2f"
        }
        
    }, [group])

    return (
        <ThemeProvider theme={theme}>
            <Accordion
                expanded={expanded}
                onChange={handleExpand}
                square={true}
                disableGutters={true}
                sx={{
                    border: "0",
                    boxShadow: "none",
                    padding: 0,
                    margin: 0,
                    width: 1,
                }}
            >
                <AccordionSummary
                    sx={{
                        border: "0px",
                        boxShadow: "none",
                        padding: 0,
                        m: "0px",
                    }}
                >
                    <Button
                        fullWidth
                        size="large"
                        startIcon={icon}
                        endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                        sx={{
                            fontSize: "15px",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            color: active ? "#0080ff" : "#000",
                            borderRadius: "10px",
                            "&:hover": {
                                backgroundColor: "#c4e1ff",
                                color: "#0080ff",
                            },
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "42.25px",
                            maxWidth: "248px",
                            m: 0,
                        }}
                        onClick={() => {
                            // dispatch(setSelectedUser(user))
                            // dispatch(setMenuOpen(false))
                        }}
                    >
                        <Typography
                            noWrap
                            width={1}
                            align="left"
                            variant="inherit"
                            style={{color: color}}
                        >
                            {group.name}
                        </Typography>
                    </Button>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        border: "0",
                        boxShadow: "none",
                        padding: 0,
                        margin: 0,
                    }}
                >
                    {children}
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
};

const UserList = ({ groups, loading, search }) => {
    var selectedUser = useSelector((state: any) => state.main.selected_user);
    var selectedGroup = useSelector((state: any) => state.main.selected_group);
    // var selectedUser = useSelector((state:any) => state.main.selectedUser)


    const groupWithUsers = useMemo(() => {
        if(groups){
            return groups.filter((group) => group.users.length > 0)
        }
        return []
    }, [groups])

    const baseUsersList = useMemo(() => {
        if (groupWithUsers.length == 0) return [];
        if(groupWithUsers.length == 1){
            return groupWithUsers[0]
            .users.filter((u) => u.active && u.roles.includes("user"))
            .sort((a, b) =>
                `${a.name} ${a.lastname}`.localeCompare(
                    `${b.name} ${b.lastname}`
                )
            );
        }
        let usersTemp = groupWithUsers.map((g) => g.users).flat()
        return usersTemp.filter((u) => u.active && u.roles.includes("user"))
        .sort((a, b) =>
            `${a.name} ${a.lastname}`.localeCompare(
                `${b.name} ${b.lastname}`
            )
        );
    }, [groupWithUsers])

    const usersList = useMemo(() => {
        if(search == "" || !search) return baseUsersList
        return baseUsersList.filter((u) => {
            let normailzedSearch = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return (
                `${u.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")} ${u.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`.includes(normailzedSearch)
                || u.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normailzedSearch)
            );
        }
        )

    }, [baseUsersList, search])

    if (loading) {
        return (
            <Grid
                container
                sx={{ justifyContent: "center", width: "100%", mt: "5px" }}
            >
                <CircularProgress size={28} />
            </Grid>
        );
    }


    if (groupWithUsers.length == 0) return "";

    if (groupWithUsers.length == 1 || (search !== "" && search)) {
        
        return (
            <>
                {(search !== "" && search) ? 
                <></>
                : <ItemUser
                    active={!selectedUser}
                    icon={<FaUserFriends size={22} />}
                    user={null}
                >
                    Todos ({usersList ? usersList.length : ""})
                </ItemUser>
                }
                {usersList &&
                    usersList.map((u) => (
                        <ItemUser
                            user={{ ...u, group: groups[0] }}
                            active={selectedUser && selectedUser._id == u._id}
                            icon={userIcon[u.userType]}
                        >
                            {u.name} {u.lastname}
                        </ItemUser>
                    ))}
            </>
        );
    }

    let minLevel;
    groups.map((g) => {
        let level = g.ancestors.length;
        if (minLevel == undefined || minLevel > level) {
            minLevel = level;
        }
    });
    let getGroupList = (g) => {
        let group_find = groups.filter(
            (grp) =>
                grp.ancestors.length - 1 >= 0 &&
                grp.ancestors[grp.ancestors.length - 1] == g._id
                && _.get(grp, 'active', true)
        );

        return { ...g, group: group_find.map((grp) => getGroupList(grp)) };
    };
    let newGroupStruct = groups.filter((g) => g.ancestors.length == minLevel);
    newGroupStruct = newGroupStruct.map((g) => getGroupList(g));

    return (
        <>
            <ItemUser
                active={!selectedUser && !selectedGroup}
                icon={<FaUsers size={22} />}
                user={null}
            >
                Toda la empresa ({groups.reduce((a, b) => a + b.users.filter((u) => u.active && u.roles.includes('user')).length, 0)})
            </ItemUser>
            <Grid container m={0} p={0} spacing={0}>
                {newGroupStruct
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((g) => _.get(g, 'active', true))
                    .map((group) => {
                        let users =
                            group.users &&
                            group.users.filter(
                                (u) => u.active && u.roles.includes("user")
                            );

                        return (
                            <>
                                {group.group.map((grp) =>
                                    generateGroup(
                                        grp,
                                        selectedUser,
                                        selectedGroup,
                                        1
                                    )
                                )}
                                {users
                                    .sort((a, b) =>
                                        `${a.name} ${a.lastname}`.localeCompare(
                                            `${b.name} ${b.lastname}`
                                        )
                                    )
                                    .map((u) => (
                                        <ItemUser
                                            user={{ ...u, group }}
                                            active={
                                                selectedUser &&
                                                selectedUser._id == u._id
                                            }
                                            icon={userIcon[u.userType]}
                                        >
                                            {u.name} {u.lastname}
                                        </ItemUser>
                                    ))}
                            </>
                        );
                    })}
            </Grid>
        </>
    );
};

export default UserList;
