import react from 'react'
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import FilterMultiSelect from "./FilterMultiSelect"
import { FormControlLabel, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

moment().locale()

interface FilterDateModal {
    open: boolean,
    setOpen: (value: boolean) => void,
    setValue: (startDate: Date | string, endDate: Date | string) => void,
    format?: string
}


const modalStyle = {
    maxWidth: '350px',
    maxHeight: '600px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    background: '#f7f9fb',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    pt: 2
}


const FilterDateModal = ({ open, setOpen, setValue, format }: FilterDateModal) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleClose = () => setOpen(false);

    const handleSubmit  = () => {
        if(format){
            if(startDate){
                if(endDate){
                    setValue(moment(startDate).add(-1, 'd').format(format),moment(endDate).add(1, 'd').format(format))
                }else{
                    setValue(moment(startDate).add(-1, 'd').format(format),endDate)
                }
            }else if(endDate){
                setValue(startDate,moment(endDate).add(1, 'd').format(format))
            }else{
                setValue(startDate, endDate)
            }
        }else{
            setValue(startDate, moment(endDate).endOf('day').toDate())
        }
        handleClose()
    }

    return (
        <Modal
                open={!!open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                        <Grid container item justifyContent="space-between" width="100%" direction={"row"}>
                                <Grid item my={1} fontSize={'18px'}>Elija un período</Grid>
                                <Grid item>
                                    <IconButton  onClick={handleClose}>
                                        <CloseIcon></CloseIcon>
                                    </IconButton>
                                </Grid>
                        </Grid>
                        <Grid container width="100%" p={1} spacing={2}>
                            
                            <Grid item width={"100%"}>
                                    <LocalizationProvider  dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            label="Desde"
                                            value={startDate}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                                        />
                                    </LocalizationProvider>
                            </Grid>
                            <Grid item width="100%">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            label="Hasta"
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                                            />
                                    </LocalizationProvider>

                        </Grid>
                        <Grid item width={"100%"}>
                            <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={handleSubmit}>Aplicar</Button>

                        </Grid>
                       </Grid>
                    </Box>
            </Modal>
    )
}


export default FilterDateModal
