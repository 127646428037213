import react from 'react'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material"
import moment, { Moment } from "moment"
import { useEffect, useState } from "react"
import FilterDateModal from './FilterDateModal'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { updateReportFilter, updateReportFilterMetadata, updateReportFilterMetadataExact } from '../../redux/slices/report'

moment().locale()

interface FilterDateInterface {
    title: string,
    valueSelected: string,
    setValueSelected: (valor: string) => void,
    setValue: (valor: dateIntervalInterface) => void,
    range: 'day' | 'week' | 'month' | 'year' | 'all',
    initialValue?: string,
    reloadOnRangeChange?: boolean,
    options: {name:string, value: string, range: ('day' | 'week' | 'month' | 'year' | 'all')[] }[]
    width?: number | string
    rangeInitialValues: {
        day?: string,
        week?: string,
        month?: string,
        year?: string,
        all?: string,

    },
    format?: string
    useReportId?:[string, number]
}

export interface dateIntervalInterface {
    lt?: string,
    gt?: string
}

let defaultInitialValue = {
    day: 'last_7_days',
    week: 'last_30_days',
    month: 'last_3_month',
    year: 'last_3_year',
    all: 'last_30_days',

}

let default_options = [
    {name: "Hoy", value: "today", range: ['day', 'all']},
    {name: "Ayer", value: "yesterday", range: ['day', 'all']},
    {name: "Últimos 7 días", value: "last_7_days", range: ['day', 'week', 'all']},
    {name: "Últimos 14 días", value: "last_14_days", range: ['day', 'week','all']},
    {name: "Últimos 30 días", value: "last_30_days", range: ['week', 'month','all']},
    {name: "Este mes", value: "this_month", range: ['week', 'month','all']},
    {name: "Mes pasado", value: "past_month", range: ['week', 'month', 'all']},
    {name: "Últimos 3 meses", value: "last_3_month", range: ['month', 'all']},
    {name: "Últimos 6 meses", value: "last_6_month", range: ['month', 'all']},
    {name: "Este año", value: "this_year", range: ['month', 'all']},
    {name: "Año pasado", value: "past_year", range: ['month','year', 'all']},
    {name: "Últimos 3 años", value: "last_3_year", range: ['year']},
    {name: "Últimos 6 años", value: "last_6_year", range: ['year']},
    {name: "Toda la historia", value: "all", range: ['year','all']},
]

const FilterDate = (params: FilterDateInterface) => {
    const { title, format, valueSelected, reloadOnRangeChange, setValue, setValueSelected, initialValue, options, range, rangeInitialValues, width, useReportId } = params
    let [localValueSelected, setLocalValueSelected] = useState(initialValue || valueSelected || 'all')
    const [open, setOpen] = useState(false);
    const [firstSetLocal, setFirstSetLocal] = useState(false);

    const dispatch = useDispatch()

    let valueSelectedTangible = useReportId ? useSelector((state:any) => _.get(state, `report.reports.${useReportId[0]}.filters_metadata[${useReportId[1]}]`, initialValue || valueSelected || 'all')) : localValueSelected
    let setValueSelectedTangible = useReportId ? (new_value) => dispatch(updateReportFilterMetadata({id: useReportId[0], filter: useReportId[1], value: new_value})) : setLocalValueSelected
    let firstSet = useReportId ? useSelector((state:any) => _.get(state, `report.reports.${useReportId[0]}.statesFilter[${useReportId[1]}]`, false)) : firstSetLocal
    let setFirstSet = useReportId ? (new_value) => dispatch(updateReportFilterMetadataExact({id: useReportId[0], filter: useReportId[1], value: new_value, url: 'firstSet'})) : setFirstSetLocal

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit  = (startDate, endDate) => {
        if(format){
            setValue({
                lt: endDate,
                gt: startDate,
            })
        }else{
            setValue({
                lt: endDate.toISOString(),
                gt: startDate.toISOString(),
            })
        }
    }

    useEffect(() => {
        let esta = options.filter((o) => o.range.includes(range) && o.value == valueSelectedTangible ).length > 0
        if(valueSelectedTangible != 'custom' && (reloadOnRangeChange || !esta)){
            if(rangeInitialValues[range]){
                makeChange(rangeInitialValues[range])
            }else{
                makeChange(options.filter((o) => o.range.includes(range))[0]?.value)
            }
            setFirstSet(true)
        }else if(!firstSet){
            makeChange(valueSelectedTangible)
            setFirstSet(true)
        }
    },[range])

    let handleChange = (e: SelectChangeEvent<string>) => {
        makeChange(e.target.value+"")
    }
    
    let momentToDate = (fecha_moment:Moment, removeOneDay?: boolean) => {
        if(format){
            if(removeOneDay) fecha_moment.add(-1, 'd')
            return fecha_moment.format(format)
        }else{
            return fecha_moment.toISOString()
        }
    }

    const makeChange = (chg:string) => {
        setValueSelectedTangible(chg)
        setValueSelected(chg)
        switch (chg) {
            case 'today':
                setValue({
                    gt: momentToDate(moment().startOf('day'), true)
                })
                break;
            case 'yesterday':
                setValue({
                    gt: momentToDate(moment().add(-1, 'day').startOf('day'), true),
                    lt: momentToDate(moment().startOf('day')),
                })
                break;
            case 'last_7_days':
                setValue({
                    gt:momentToDate(moment().add(-6, 'day').startOf('day'), true),
                })
                break;
            case 'last_14_days':
                setValue({
                    gt:momentToDate(moment().add(-13, 'day').startOf('day'), true),
                })
                break;
            case 'last_30_days':
                setValue({
                    gt:momentToDate(moment().add(-29, 'day').startOf('day'), true),
                })
                break;
            case 'this_month':
                setValue({
                    gt:momentToDate(moment().startOf('month').startOf('day'), true),
                })
                break;
            case 'past_month':
                setValue({
                    lt:momentToDate(moment().startOf('month').startOf('day')),
                    gt:momentToDate(moment().add(-1, 'month').startOf('month').startOf('day'), true),
                })
                break;

            case 'last_3_month':
                setValue({
                    gt:momentToDate(moment().add(-2, 'month').startOf('month').startOf('day'), true),
                })
                break;
            case 'last_6_month':
                setValue({
                    gt:momentToDate(moment().add(-5, 'month').startOf('month').startOf('day'), true),
                })
                break;
            case 'this_year':
                setValue({
                    gt:momentToDate(moment().startOf('year').startOf('day'), true),
                })
                break;
            case 'last_3_year':
                setValue({
                    gt:momentToDate(moment().add(-2, 'year').startOf('year').startOf('day'), true),
                })
                break;
            case 'last_6_year':
                setValue({
                    gt:momentToDate(moment().add(-5, 'year').startOf('year').startOf('day'), true),
                })
                break;
            case 'past_year':
                setValue({

                    lt:momentToDate(moment().startOf('year').startOf('day')),
                    gt:momentToDate(moment().add(-1, 'year').startOf('year').startOf('day'), true),
                })
                break;
            case 'all':
                setValue({
                })
                break;
            case 'custom':
                break;
            default:
                setValue({
                })
                break;


        }
    }

    return (
        <div>
            <FilterDateModal setValue={handleSubmit} open={open} setOpen={setOpen} format={format} />
            <FormControl sx={{ width: width ?? "200px", mr: 2, my: 1, }} size="small">
                <InputLabel>{title}</InputLabel>
                <Select
                    value={valueSelectedTangible}
                    label={title}
                    size="small"
                    color="primary"
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width:250,
                            },
                        },
                    }}
                >
                    {options.filter((o) => o.range.includes(range) ).map((o) => (
                    <MenuItem key={o.value} sx={{ py: 0.1 }} value={o.value}>{o.name}</MenuItem>
                    ))}
                    <MenuItem sx={{ py: 0.1 }} value={'custom'} onClick={handleOpen}>Personalizado</MenuItem>
                 </Select>
            </FormControl>

        </div>

    )
}

FilterDate.defaultProps = {
    setValueSelected: () => { },
    valueSelected: 'all',
    options: default_options,
    range: 'all',
    rangeInitialValues: defaultInitialValue
}

export default FilterDate