import _ from "lodash";
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { isAuth } from "../../../actions/auth";
import { updateReportFilter, updateReportFilterMetadata, updateReportFilterMetadataExact } from "../../../redux/slices/report";


interface useReportUserFilterInterface {
    useAsUserInit?: any; // Usuario con modelo isAuth()
    useReportId?: [string, number]
}


const useFilterUser = (url: string, useReportId: [string, number], defaultValue:any, initObject) => {
    let value = useSelector((state:any) => _.get(state, `report.reports[${useReportId[0]}].statesFilter[${url}-${useReportId[1]}]`, initObject[url] ?? defaultValue)) 

    const dispatch = useDispatch()
    let setValue = (new_value) => {
        dispatch(updateReportFilterMetadataExact({id: useReportId[0], filter: useReportId[1], value: new_value, url, initObject}))
    }




    return [value, setValue]
}


const useReportUserFilter = ({useAsUserInit, useReportId}:useReportUserFilterInterface) => {
    const dispatch = useDispatch()
    
    let initObject = {
        activeUsers: [],
        activeGroups: [],
        userFilter: ['all'],
        groupFilter: ['all'],
        actualUser: isAuth(),
        usersExtraFilters: {},
        allUsers: [],
        groupUsers: [],
        useAsUser: useAsUserInit
    }

    let [activeUsers, setActiveUsers] = useReportId ? useFilterUser('activeUsers', useReportId, [], initObject) : useState([]) 
    let [activeGroups, setActiveGroups] = useReportId ? useFilterUser('activeGroups', useReportId, [], initObject) : useState([])
    let [userFilter, setUserFilter] = useReportId ? useFilterUser('userFilter', useReportId, ['all'], initObject) : useState(['all'])
    let [groupFilter, setGroupFilter] = useReportId ? useFilterUser('groupFilter', useReportId, ['all'], initObject) : useState(['all'])
    let [actualUser, setActualUser] = useReportId ? useFilterUser('actualUser', useReportId, isAuth(), initObject) : useState(isAuth())
    let [usersExtraFilters, setUsersExtraFilters] = useReportId ? useFilterUser('usersExtraFilters', useReportId, {}, initObject) : useState({})
    let [allUsers, setAllUsers] = useReportId ? useFilterUser('allUsers', useReportId, [], initObject) : useState([])
    let [groupUsers, setGroupUsers] = useReportId ? useFilterUser('groupusers', useReportId, [], initObject) : useState([])
    let [useAsUser, setUseAsUser] = useReportId ? useFilterUser('useAsUser', useReportId, useAsUserInit, initObject) : useState(useAsUserInit)


    return [
        {
            activeUsers,
            activeGroups,
            userFilter,
            groupFilter,
            actualUser,
            usersExtraFilters,
            allUsers,
            groupUsers,
            useAsUser
        },
        {
            setActiveGroups,
            setActiveUsers,
            setUserFilter,
            setGroupFilter,
            setActualUser,
            setUsersExtraFilters,
            setAllUsers,
            setGroupUsers,
            setUseAsUser
        }
    ]
}

export default useReportUserFilter