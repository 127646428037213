import Head from 'next/head'
import { useEffect, useState } from 'react';
import { isAuth, setChaportId, removeCookie } from '../actions/auth'
import { useRouter } from 'next/router';
import { Box, typography } from '@mui/system';
import { Button, Grid, Tab, Tabs, StyledBadge, Typography, CircularProgress, Tooltip, IconButton, TextField, Collapse } from '@mui/material';
import { Close, PeopleAlt, Person, Search } from '@mui/icons-material'
import PropTypes from 'prop-types';
import { setMenuOpen, setUsers, setSelectedUser, setUsersGroup } from '../redux/actions/main';
import { connect } from 'react-redux';
import { getLastGroup } from '../actions/call'
import { getActiveUsers, getUsersGroup } from '../actions/group'
import MenuTitle from './core/MenuTitle'
import UserList from './core/UserList'


function MenuConfig({ path, open, setOpen, children,usersGroup, setUsersGroup, setUsers, users, setSelectedUser, selectedUser, active }) {

    let [user, setUser] = useState({})
    let [loading, setLoading] = useState({})
    let [openSearch, setOpenSearch] = useState(false)
    let [search, setSearch] = useState("")

    let router = useRouter()
    // useEffect(() => {
    //     if (active) {
    //         setUser(isAuth())
    //         if (!users || users.length > 0) setLoading(true)
    //         getActiveUsers().then((u) => {
    //             if (!u) return
    //             setLoading(false)
    //             setUsers(u.sort((a, b) => `${a.name} ${a.lastname}`.localeCompare(`${b.name} ${b.lastname}`)))
    //         })
    //         setSelectedUser(null)
    //     }
    // }, [])

    useEffect(() => {
        if (active) {
            setUser(isAuth())
            if (!users || users.length > 0) setLoading(true)
            getUsersGroup().then((u) => {
                if (!u) return
                setUsersGroup(u)
            })
            setSelectedUser(null)
        }
    }, [])

    const handleSearch = () => {
        setSearch("")
        setOpenSearch(!openSearch)
    }



    return !active ? <div>{children}</div> : (
        <Box sx={{ display: 'grid', gridTemplateColumns: {xs: (!open ? '0 auto' : '100% 0px'), sm: '280px auto'}, height: '100vh' }}>
            <Grid container direction="column" alignItems="center" p={2} width={1} sx={{
                zIndex: 1,
                marginLeft: {xs: (!open ? '-280px' : 0), sm: 0},
                transition: 'margin .5s',
                flexShrink: 0,
                boxSizing: 'border-box',
                overflowX: 'clip',
                '& .MuiDrawer-paper': {
                    marginLeft: {xs: (!open ? '-280px' : 0), sm: 0},
                    transition: 'margin .5s',
                    boxSizing: 'border-box',
                    overflowX: 'clip'
                },
                background: '#fff',
                alignItems: 'left',
                borderRight: '1px solid #f6f6f6'
            }}>
                <MenuTitle>Vendedores</MenuTitle>
                <Grid container item sx={{display: 'flex', width: '100%', mb: 0.75}}>
                    <Grid item>
                        <IconButton onClick={handleSearch} sx={{mt: 0.75}} >
                            {openSearch ? <Close /> : <Search />}
                        </IconButton>
                    </Grid>
                    <Grid item xs sx={{width: '100%'}}>
                        <Collapse in={openSearch} timeout="auto" unmountOnExit orientation='horizontal' sx={{width: '100%'}}>
                            <TextField
                            label="Buscar"
                            // variant="contained"
                            size="small"
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            autoComplete="off"
                            autoCorrect='off'
                            autoCapitalize='off'
                            spellCheck='false'
                            autoFocus={true}
                            sx={{mt: 0.75}}
                            />
                        </Collapse>       
                    </Grid>         
                </Grid>
                <UserList groups={usersGroup} search={search} />
            </Grid>
            {children}
        </Box>
    )
}

const mapStateToProps = state => ({
    open: state.main.open,
    users: state.main.users,
    selectedUser: state.main.selected_user,
    usersGroup: state.main.usersGroup
})

const mapDispatchToProps = {
    setOpen: setMenuOpen,
    setUsers: setUsers,
    setUsersGroup: setUsersGroup,
    setSelectedUser: setSelectedUser
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuConfig)