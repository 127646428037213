// Todavia no esta terminado, es solo para probar nueva interfaz

import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, FormControl, IconButton, InputLabel, ListItem, ListItemButton, ListItemText, MenuItem, Select, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"

const GroupFilterCRM = ({activeGroups, handleActiveGroupChange}) => {

    const [openSubMenu, setOpenSubMenu] = useState<string[]>([])
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [localGroup, setLocalGroup] = useState<string>("all")
    

    const generateFlatGroupListTop = useCallback((groups) => {
        let result = []
        groups.map((g) => {
            result = [...result, ...generateFlatGroupList(g, 0)]
        })
        return result
    }, [])

    let generateFlatGroupList = useCallback((group, ml) => {
        let groupList = [{...group, ml}]
        if(group.group) group.group.map(g => {
            groupList = [...groupList , ...generateFlatGroupList(g, ml+1)]
        })
        return groupList
    }, [])


    const generateGroupList = useCallback((groupList: any[]) => {
        let minLevel;
        groupList.map((g) => {
            let level = g.ancestors.length
            if(minLevel == undefined || minLevel > level){
                minLevel = level
            }
        })

        return groupList
            .filter((g) => g.ancestors.length == minLevel)
            .map((g) => getGroupList(g, groupList))
    }, [])

    const getGroupList = useCallback((g, groupList) => {
        let group_find = groupList.filter((grp => grp.ancestors.length-1 >= 0 && grp.ancestors[grp.ancestors.length-1] == g._id))
        return {...g, group: group_find.map((grp) => getGroupList(grp, groupList))}
    }, [])


    const groupsTree = useMemo(()=> {
        return generateGroupList(activeGroups)
     }, [activeGroups])

    const groupsFlatTree = useMemo(()=> {
       return generateFlatGroupListTop(groupsTree)
    }, [groupsTree])



    const groupsText = useMemo(()=> {
        if(localGroup == 'all'){
            return 'Todos'
        }
        return activeGroups.filter(g => g._id == localGroup).map((g) => g.name).join(',')
     }, [activeGroups, localGroup])

    const handleExpandSubMenu = useCallback((groupId: string, openThis: boolean) => {
        if(openThis){
            setOpenSubMenu([...openSubMenu, groupId])
        }else{
            setOpenSubMenu(openSubMenu.filter( g => g != groupId))
        }
    }, [])

    const handleGroupChange = (value:string) => {
        setLocalGroup(value)
        console.log(value)
        let listOfGroups = activeGroups.filter((g) => g.ancestors.includes(value) || value == g._id).map((g) => g._id)


        handleActiveGroupChange(listOfGroups)

    }

    return (
        <FormControl sx={{width: "200px", mr:2, my: 1,}} size="small">
                    <InputLabel>Grupo</InputLabel>
                    <Select
                        labelId="simple-select"
                        id="form-simple-select"
                        size="small"
                        color="primary"
                        MenuProps={{
                        PaperProps: {
                            style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                            },
                        },
                        }}
                        onOpen={() => setOpenMenu(true)}
                        onClose={() => setOpenMenu(false)}
                        open={openMenu}
                        name="group"
                        label="Grupo"
                        value={localGroup}          
                        renderValue={() => groupsText}
                        onChange={(e) => handleGroupChange(e.target.value)}
                    >
                        {groupsFlatTree ?
                        groupsFlatTree.map((g) => {
                            if(g.ml == 0) return <ListItemButton
                            onClick={() => handleGroupChange('all')}
                            sx={{pl: 2, ml: 0, fontSize: 16, fontWeight: 600}}
                            >{g.name}</ListItemButton>
                            if(g.ml > 1 &&  !openSubMenu.includes(g.ancestors[g.ancestors.length - 1])){
                               return false
                            }
                            return (
                                <ListItem 
                                    key={g._id}
                                    sx={{ml:0}}
                                    secondaryAction={
                                        g.group.length != 0 ?
                                        <IconButton edge="end" onClick={()=> handleExpandSubMenu(g._id, !openSubMenu.includes(g._id))}>
                                            { openSubMenu.includes(g._id) ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                        : undefined
                                    }
                                    disablePadding
                                    >
                                        <ListItemButton 
                                        sx={{pl: g.ml+2, ml:0}}
                                        role={undefined}
                                        onClick={() => handleGroupChange(g._id)}
                                        dense
                                        >
                                            <ListItemText id={g._id} primary={<Typography sx={{color: localGroup == g._id ? "#0080FE" : "inherit", fontWeight: localGroup == g._id ? 600 : "inherit", fontSize: 14, p: 0}}>{g.name}</Typography>}  />
                                        </ListItemButton>

                                </ListItem>
                            )
                            }) 
                                : 
                                activeGroups && activeGroups.map((g) => (
                                    <MenuItem value={g._id}>{g.name}</MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
    )
}

export default GroupFilterCRM