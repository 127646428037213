import { Typography } from "@mui/material";

const MenuTitle = ({ children }) => (
    <Typography
        variant="h6"
        sx={{
            backgroundColor: "#ddeeff",
            fontWeight: "bold",
            lineHeight: 1,
            padding: "20px",
            width: "100%",
            borderRadius: "10px",
            color: "#0080ff",
            mb: 1,
        }}
    >
        {children}
    </Typography>
);

export default MenuTitle;
